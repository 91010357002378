import { createContext, useState } from "react";

type officeContextType = {
  currentOffice: number | undefined;
  setCurrentOffice: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const officeContextState = {
  currentOffice: undefined,
  setCurrentOffice: () => "",
};

const officeContext = createContext<officeContextType>(officeContextState);

const OfficeProvider = (props: { children: React.ReactNode }) => {
  const [currentOffice, setCurrentOffice] = useState<number | undefined>();

  return (
    <officeContext.Provider value={{ currentOffice, setCurrentOffice }}>
      {props.children}
    </officeContext.Provider>
  );
};

export { officeContext, OfficeProvider };
